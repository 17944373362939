<template>
  <v-theme-provider>
    <base-section
      id="boss-introduction"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-img
              :src="require('@/assets/ceo.png')"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <br>
            <br>
            <br>
            <br>
            <v-row>
              <base-section-heading
                class="align-self-center mx-auto"
                title="Managing Director Message"
              />
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
                class="mx-auto"
              >
                <p>
                  Thank you for your interest in our company, Howellcare Industries Sdn Bhd. <br><br> It is our delight and pride that we have grown and thrived since incorporated in 2019 with the strong support of our business partners and our greatest fortune – our team. <br><br>

                  We now face a new era where personal barrier protection is in even greater demand especially in the glove industry.<br><br>

                  We are committed to becoming a leading one-stop nitrile manufacturing solution to the industry with the expertise and technology at Howellcare Industries Sdn Bhd.  <br><br>

                  We are confident that with our competencies throughout the manufacturing process from design, fabrication, installation, commissioning and production, we are able to successfully contribute to the glove industry by providing excellent barrier protection products that are of high quality, innovative and reliable to the end-users.<br><br>

                  Our commitment to improving global health and being a responsible business will continue under my leadership.  Thank you for your support.<br><br>
                </p>
                <h2><i>Francis Ho Chia Yao</i></h2>
                <h4>Managing Director</h4>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBossIntroduction',
  }
</script>
